import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import storyImages from '../images/story/'

const Story = () => (
  <Layout className="story">
    <SEO title="Our Story" />
    <h1>Our Story</h1>
    <div className="story-container box-container">
      <h2>
        We met snowboarding in the French Alps...
      </h2>
      <p>
        Our mutual friend Magda (Esther's best Woman),
        thought we'd be great for each other
        and apparently she was very correct!
        We started dating very soon after our trip to Tignes
        and the rest
      </p>
      <h2>...is history!</h2>
      <div className="story-img-container">
        {storyImages.map(src =>
          <img src={src} alt='story' className="story-img" />
        )}
      </div>
    </div>
  </Layout>
)

export default Story
