import story1 from './450/1.jpg'
import story2 from './450/2.jpg'
import story3 from './450/3.jpg'
import story4 from './450/4.jpg'
import story5 from './450/5.jpg'
import story6 from './450/6.jpg'
import story7 from './450/7.jpg'
import story8 from './450/8.jpg'
import story9 from './450/9.jpg'
import story10 from './450/10.jpg'
import story11 from './450/11.jpg'
import story12 from './450/12.jpg'
import story13 from './450/13.jpg'
import story14 from './450/14.jpg'
import story15 from './450/15.jpg'
import story16 from './450/16.jpg'
import story17 from './450/17.jpg'
import story18 from './450/18.jpg'
import story19 from './450/19.jpg'
import story20 from './450/20.jpg'
import story21 from './450/21.jpg'
import story22 from './450/22.jpg'
import story23 from './450/23.jpg'
import story24 from './450/24.jpg'
import story25 from './450/25.jpg'
import story26 from './450/26.jpg'
import story27 from './450/27.jpg'
import story28 from './450/28.jpg'
import story29 from './450/29.jpg'
import story30 from './450/30.jpg'
import story31 from './450/31.jpg'
import story32 from './450/32.jpg'
import story33 from './450/33.jpg'
import story34 from './450/34.jpg'
import story35 from './450/35.jpg'
import story36 from './450/36.jpg'
import story37 from './450/37.jpg'
import story38 from './450/38.jpg'
import story39 from './450/39.jpg'
import story40 from './450/40.jpg'
import story41 from './450/41.jpg'
import story42 from './450/42.jpg'
import story43 from './450/43.jpg'
import story44 from './450/44.jpg'
import story45 from './450/45.jpg'
import story46 from './450/46.jpg'
import story47 from './450/47.jpg'
import story48 from './450/48.jpg'
import story49 from './450/49.jpg'
import story50 from './450/50.jpg'
import story51 from './450/51.jpg'
import story52 from './450/52.jpg'
import story53 from './450/53.jpg'
import story54 from './450/54.jpg'
import story55 from './450/55.jpg'
import story56 from './450/56.jpg'
import story57 from './450/57.jpg'
import story58 from './450/58.jpg'
import story59 from './450/59.jpg'
import story60 from './450/60.jpg'

import x20210806121340 from './450/20210806_121340.jpg'
import x20210910211214 from './450/20210910_211214.jpg'
import x20210918180352 from './450/20210918_180352.jpg'
import x20210908190555 from './450/20210908_190555.jpg'
import IMG20200719095646 from './450/IMG_20200719_095646.jpg'
import IMG20200816WA0013 from './450/IMG-20200816-WA0013.jpg'
import IMG20200911WA0000 from './450/IMG-20200911-WA0000.jpg'
import IMG20201206WA0007 from './450/IMG-20201206-WA0007.jpg'
import IMG20200804WA0046 from './450/IMG-20200804-WA0046.jpg'
import IMG20200718132619 from './450/IMG_20200718_132619.jpg'
import IMG20200906WA0038 from './450/IMG-20200906-WA0038.jpg'
import IMG20200905WA0007 from './450/IMG-20200905-WA0007.jpg'
import IMG20200907WA0016 from './450/IMG-20200907-WA0016.jpg'
import IMG20200726WA0015 from './450/IMG-20200726-WA0015.jpg'
import IMG20200830WA0005 from './450/IMG-20200830-WA0005.jpg'
import IMG20200808WA0006 from './450/IMG-20200808-WA0006.jpg'
import IMG20201101WA0015 from './450/IMG-20201101-WA0015.jpg'
import IMG20200827WA0006 from './450/IMG-20200827-WA0006.jpg'
import IMG20200802WA0071 from './450/IMG-20200802-WA0071.jpg'
import IMG20200725WA0008 from './450/IMG-20200725-WA0008.jpg'
import IMG20201101WA0012 from './450/IMG-20201101-WA0012.jpg'
import IMG20200906WA0023 from './450/IMG-20200906-WA0023.jpg'
import IMG20200625WA0004 from './450/IMG-20200625-WA0004.jpg'
import IMG20200905WA0011 from './450/IMG-20200905-WA0011.jpg'
import IMG20200724WA0022 from './450/IMG-20200724-WA0022.jpg'
import IMG20200906WA0027 from './450/IMG-20200906-WA0027.jpg'
import IMG20200727WA0022 from './450/IMG-20200727-WA0022.jpg'
import IMG20201230WA0002 from './450/IMG-20201230-WA0002.jpg'
import IMG20200723WA0009 from './450/IMG-20200723-WA0009.jpg'
import IMG20200803WA0038 from './450/IMG-20200803-WA0038.jpg'
import IMG20200814WA0016 from './450/IMG-20200814-WA0016.jpg'
import IMG20200906WA0002 from './450/IMG-20200906-WA0002.jpg'
import IMG20200906WA0045 from './450/IMG-20200906-WA0045.jpg'
import IMG20200906WA0003 from './450/IMG-20200906-WA0003.jpg'
import IMG20200816WA0017 from './450/IMG-20200816-WA0017.jpg'
import IMG20211222153350865 from './450/IMG20211222153350865.jpg'

export default [
  story1,
  story56,
  story11,
  story2,
  story3,
  story4,
  story5,
  story6,
  story31,
  story32,
  story33,
  story34,
  story35,
  story36,
  story37,
  story7,
  story8,
  story9,
  story10,
  story12,
  story13,
  story14,
  story15,
  story16,
  story17,
  story18,
  story19,
  story20,
  story21,
  story22,
  story23,
  story24,
  story57,
  story38,
  story39,
  story25,
  story26,
  story27,
  story55,
  story40,
  story41,
  story28,
  story42,
  story43,
  story44,
  story29,
  story45,
  story46,
  story47,
  story48,
  story30,
  story49,
  story50,
  story51,
  story52,
  story53,
  story54,
  story58,
  story59,
  story60,
    
    x20210806121340,
    x20210908190555,
    x20210910211214,
    x20210918180352,
    IMG20200625WA0004,
    IMG20200718132619,
    IMG20200719095646,
    IMG20200724WA0022,
    IMG20200723WA0009,
    IMG20200725WA0008,
    IMG20200726WA0015,
    IMG20200727WA0022,
    IMG20200802WA0071,
    IMG20200803WA0038,
    IMG20200804WA0046,
    IMG20200808WA0006,
    IMG20200814WA0016,
    IMG20200816WA0013,
    IMG20200816WA0017,
    IMG20200827WA0006,
    IMG20200830WA0005,
    IMG20200905WA0007,
    IMG20200905WA0011,
    IMG20200906WA0002,
    IMG20200906WA0003,
    IMG20200906WA0023,
    IMG20200906WA0027,
    IMG20200906WA0038,
    IMG20200906WA0045,
    IMG20200907WA0016,
    IMG20200911WA0000,
    IMG20201101WA0012,
    IMG20201101WA0015,
    IMG20201206WA0007,
    IMG20201230WA0002,
    IMG20211222153350865,
];
